.custom-container {
  max-width: 1200px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}

.top-bar {
  backdrop-filter: blur(40px);
}

.hero {
  background: radial-gradient(
    78.91% 78.91% at 50% 50%,
    #11172b 32.67%,
    #070a15 100%
  );
  border: 4px solid rgba(56, 132, 247, 0.2);
  border-top: 0;
  border-radius: 0px 0px 250px 250px;
}

body,
html {
  background-color: #000000;
}

.btn-blue {
  background: linear-gradient(180deg, #527ff4 0%, #4042c3 100%);
  backdrop-filter: blur(13px);
  color: white;
  padding: 8px 16px;
  background-repeat: no-repeat;
  background-size: cover;
}

.btn-red {
  background: linear-gradient(180deg, #fc4d58 0%, #d6333d 48.96%, #ae1721 100%);
  background-size: cover;
  background-repeat: no-repeat;
  backdrop-filter: blur(13px);
  padding: 8px 16px;
  color: white;
}

.user-select {
  backdrop-filter: blur(13px);
  border: 2px solid #5078ee;
}

.user-img {
  transform: translateY(-50%);
}

.prizemoney {
  background: radial-gradient(
    62.74% 93.22% at 50% 0%,
    #d84f46 0%,
    #6a2222 100%
  );
  box-shadow: 0px 0px 2.88915px rgba(0, 0, 0, 0.57),
    0px 10.112px 39.0036px rgba(0, 0, 0, 0.66),
    inset 0px -4px 4px rgba(218, 91, 84, 0.2),
    inset 0px 4px 4px rgba(0, 0, 0, 0.31);
  backdrop-filter: blur(14.4458px);
  border-radius: 28px 28px 32px 32px;
  border: 2px solid rgba(255, 200, 0, 0.64);
}

.winning-btns {
  background: linear-gradient(180deg, #22346d 0%, #1b2852 100%);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
}

.play-btn {
  background: linear-gradient(94.07deg, #5282ef 9.17%, #4345b8 90.34%);
  box-shadow: 0px -4px 26px rgba(64, 133, 236, 0.3),
    0px 4px 26px rgba(126, 26, 255, 0.3),
    inset -12px -12px 20px rgba(0, 0, 0, 0.25),
    inset 12px 12px 21px rgba(185, 206, 255, 0.3);
}

.enter-btn-gray {
  background: linear-gradient(187.37deg, #a7a7a7 6.23%, #686868 92.16%);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25),
    inset 0px -1px 1px rgba(0, 0, 0, 0.25),
    inset 0px 1px 1px rgba(255, 255, 255, 0.25);
  border-radius: 50px;
}

.tablee {
  background: url("../images/table-bg.png");
  background-size: 100%;
  background-position: right center;
  background-repeat: no-repeat;
}

.custom-table-bg {
  background: linear-gradient(
    89.6deg,
    rgba(12, 19, 182, 0.46) 0.81%,
    rgba(23, 31, 225, 0.3404) 53.47%,
    rgba(31, 40, 255, 0.46) 99.94%
  );
  border: 0.999992px solid #0703a4;
  backdrop-filter: blur(21.9998px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 23.9998px 11.9999px;
  padding: 24px 32px;
}

.past-winners {
  background: #11172b;
  box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.5);
  border-radius: 24px;
  border: 2px solid #6f97f4;
}

.initiate-play {
  background: #11172b;
  box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.5);
  border-radius: 24px;
  border: 2px solid #6f97f4;
  overflow: hidden;
}

.modal-header {
  background: radial-gradient(
      56.57% 33.35% at 50% 66.65%,
      rgba(0, 0, 0, 0.49) 0%,
      rgba(0, 0, 0, 0) 69.34%
    ),
    linear-gradient(180deg, #21336a 0%, #1e2b5a 100%);
}

.modal-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.play-btn {
  background: linear-gradient(187.37deg, #6d96f3 6.23%, #393b9d 92.16%);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25),
    inset 0px -1px 1px rgba(0, 0, 0, 0.25),
    inset 0px 1px 1px rgba(255, 255, 255, 0.25);
  border-radius: 50px;
}

.selected-ball {
  background: linear-gradient(180deg, #527cf2 0%, #4145c5 100%);
}

.not-selected-ball {
  background: #12204d;
  border: 1px solid #2d4285;
  border-radius: 40px;
}

.btn-gotit {
  background: linear-gradient(187.37deg, #6d96f3 6.23%, #393b9d 92.16%);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25),
    inset 0px -1px 1px rgba(0, 0, 0, 0.25),
    inset 0px 1px 1px rgba(255, 255, 255, 0.25);
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.connect-btn {
  background: linear-gradient(180deg, #527ff4 0%, #4042c3 100%);
  backdrop-filter: blur(13px);
  color: white;
  background-repeat: no-repeat;
  padding: 8px 16px;
}

.bottom-card .card-body {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 8px 24px 8px 24px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  flex-shrink: 0;
  border-radius: 8px;
  background: radial-gradient(50% 50% at 50% 50%, #1a2750 0%, #18213e 100%);
  box-shadow: 0px 4px 10px 0px rgba(125, 25, 224, 0.6), 0px 2px 4px 0px #7d19e0,
    0px -4px 10px 0px rgba(48, 159, 240, 0.6), 0px -2px 4px 0px #309ff0;
}

.bottom-card .card-header {
  display: flex;
  width: 100%;
  padding: 5.778px 34.67px 11.557px 34.67px;
  flex-direction: column;
  align-items: center;
  gap: 5.778px;
  border-radius: 28px 28px 32px 32px;
  border: 2px solid #ffc700;
  background: radial-gradient(
    62.74% 93.22% at 50% 0%,
    #a13932 0%,
    #501c1c 100%
  );
  box-shadow: 0px 10.11203px 39.00356px 0px rgba(0, 0, 0, 0.66),
    0px 0px 2.88915px 0px rgba(0, 0, 0, 0.57),
    0px 4px 4px 0px rgba(0, 0, 0, 0.31) inset,
    0px -4px 4px 0px rgba(218, 91, 84, 0.2) inset;
  backdrop-filter: blur(14.445762634277344px);
  position: relative;
  top: 10px;
  margin-top: -10px;
}
.container {
  max-width: 1280px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}
.mint-btn-new:hover {
  /* Button Shadows/Button - 4dp - Shadow */
  box-shadow: 0px 4px 8px 0px rgba(50, 50, 71, 0.06),
    0px 4px 4px 0px rgba(50, 50, 71, 0.08);
}

.btn-no {
  border-radius: 50px;
  background: linear-gradient(
    187deg,
    #6d96f3 6.23%,
    #92a5d0 9.92%,
    #494a73 92.16%
  );
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25),
    0px 1px 1px 0px rgba(255, 255, 255, 0.25) inset,
    0px -1px 1px 0px rgba(0, 0, 0, 0.25) inset;
}

.custom-radio {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 50%;
  border: 1px solid #717171;
  background: rgba(0, 0, 0, 0.29);
}

/* .select-nft-form input[type="radio"] {
  display: none;
} */

/* .select-nft-form input[type="radio"]:checked + .custom-radio {
  background: #e4e4e4;
  border: 1px solid #717171;
} */

/* .select-nft-form label {
  cursor: pointer;
} */

.leftImg1,
.rightImg1 {
  transform: scale(1.25);
}

.circle {
  display: flex;
  width: 50px;
  height: 50px;
  background-color: black;
  border-radius: 50%;
}

@media screen and (max-width: 767px) {
  .mobile-menu,
  .menu-overlay {
    transition: 0.5s;
  }

  .past-winners {
    border: unset;
    border-radius: 0;
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    padding: 10px;
  }

  .past-winners p {
    font-size: 11px;
  }

  .custom-table * {
    font-size: 11px;
  }

  .initiate-play {
    border-radius: 24px 24px 0 0;
    border-bottom: unset;
  }
  .footer * {
    font-size: 12px;
  }
  .text-\[40px\] {
    font-size: 20px;
  }
  .modal {
    border: unset;
    border-radius: 0;
    height: 100vh;
  }
  .hero {
    border-radius: 0 0 100px 100px;
  }
  .custom-table p.text-2xl {
    font-size: 20px;
  }
  .init-new-modal {
    height: 100vh;
    overflow-y: scroll;
  }
  .top {
    padding-bottom: 0 !important;
    height: auto;
  }
}

@media screen and (max-width: 700px) {
  .modal {
    z-index: 100000000000000;
  }
}
